import React from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as UpChunk from '@mux/upchunk';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import FormData from 'form-data';
import axiosRequest from '../../config/axios/axios';
import { SocialNetworks, SpecInfoData, UserInfoData } from '../types';
import {
  NotificationsModel,
  NotificationsResponseModel,
  CreatorSpecsModel,
} from '../types/databaseSchemas';
import { NextRouter } from 'next/router';
import { PlaceType, ProfileParams, SpecParams } from '../../utils/types';
import { hmsToSeconds, secondsToHms } from '../../utils';
import { CreatorModel } from '../types/databaseSchemas';
import { supabase } from '../../utils/supabase';
import { SupabaseRealtimePayload } from '@supabase/supabase-js';
import { ACTION_DELAY } from '../../utils/constants';

const manageError = (error: any) => {
  if (error?.response?.status === 401) {
    localStorage.clear();
    window.location.href = `${process.env.NEXT_PUBLIC_LANDING_URL}/login?error_code=401`;
  }
};

export const logIn = (
  dispatch: React.Dispatch<Action>,
  user: { name: string }
) => {
  dispatch({
    type: ActionType.SET_USER,
    payload: { ...user, loggedIn: true },
  });
};

export const logOut = (dispatch: React.Dispatch<Action>) => {
  dispatch({
    type: ActionType.SET_USER,
    payload: { name: '', loggedIn: false },
  });
};

export const setLoadFiles = (
  dispatch: React.Dispatch<Action>,
  files: File[],
  fileType: 'video' | 'other'
) => {
  switch (fileType) {
    case 'video':
      dispatch({
        type: ActionType.SET_LOAD_VIDEO,
        payload: { name: files[0].name, type: files[0].type, file: files[0] },
      });
      break;
    default:
      console.error('File type not supported');
  }
};

export const setStartUploadingVideo = (
  dispatch: React.Dispatch<Action>,
  files: File | undefined
): UpChunk.UpChunk | undefined => {
  if (files) {
    const upload = UpChunk.createUpload({
      endpoint: () => createUpload(dispatch),
      file: files,
    });

    upload.on('error', (err) => {
      setError(dispatch, err.detail);
    });

    upload.on('progress', (progress) => {
      if (progress.detail < 100)
        dispatch({
          type: ActionType.SET_VIDEO_UPLOAD_PROGRESS,
          payload: Math.floor(progress.detail),
        });
    });

    upload.on('success', () => {
      dispatch({
        type: ActionType.SET_VIDEO_UPLOADED,
        payload: true,
      });
    });

    return upload;
  }
  return undefined;
};

export const setCancelUploadingVideo = (dispatch: React.Dispatch<Action>) => {
  dispatch({
    type: ActionType.SET_VIDEO_UPLOAD_CANCEL,
  });
};

export const setLoadVideoCancel = (dispatch: React.Dispatch<Action>) => {
  dispatch({
    type: ActionType.SET_VIDEO_CANCEL,
  });
};

export const setError = (
  dispatch: React.Dispatch<Action>,
  msg: string | null
) => {
  dispatch({
    type: ActionType.SET_ERROR,
    payload: msg,
  });
};

export const setSnackbar = (
  dispatch: React.Dispatch<Action>,
  variant: 'error' | 'pending' | 'success',
  message: string
) => {
  dispatch({
    type: ActionType.SET_SNACKBAR,
    payload: {
      variant,
      message,
    },
  });
};

const createUpload = async (
  dispatch: React.Dispatch<Action>
): Promise<string> => {
  try {
    return fetch('/api/upload', {
      method: 'POST',
    })
      .then((res) => res.json() as Promise<{ id: string; url: string }>)
      .then(({ id, url }) => {
        dispatch({
          type: ActionType.SET_VIDEO_UPLOAD_ID,
          payload: id,
        });
        return url;
      });
  } catch (error) {
    manageError(error);
    setError(dispatch, 'Error creating upload');
    throw error;
  }
};

export const setFile = async (
  dispatch: React.Dispatch<Action>,
  file: File,
  signal: AbortSignal,
  spec_id?: string
) => {
  try {
    let percent: number = 0;
    let data: FormData = new FormData();
    data.append('file', file);
    const config: AxiosRequestConfig = {
      signal: signal,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent;
        percent = Math.floor((loaded * 100) / total);

        if (percent <= 100) {
          dispatch({
            type: ActionType.SET_UPLOAD_PROGRESS_FILE,
            payload: {
              filename: file.name,
              completed: percent === 100 ? 90 : percent,
            },
          });
        }
      },
    };

    await axiosRequest
      .post(`/api/release-forms?id=${spec_id}`, data, config)
      .then((res) => {
        dispatch({
          type: ActionType.SET_UPLOAD_PROGRESS_FILE,
          payload: {
            filename: file.name,
            completed: percent,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.SET_UPLOAD_PROGRESS_FILE,
          payload: {
            filename: file.name,
            completed: 0,
            error: 'There was an issue uploading your file.',
          },
        });
      });
  } catch (error) {
    manageError(error);
  }
};

export const deleteFile = (
  dispatch: React.Dispatch<Action>,
  filename: string
) => {
  try {
    dispatch({
      type: ActionType.SET_CANCEL_UPLOAD_FILE,
      payload: { filename },
    });

    let times = 5;
    const execDelete = async () => {
      const response = await axiosRequest.delete(
        `/api/release-forms/${filename}`
      );

      if (response.data.status === 200) {
        times = 0;
        dispatch({
          type: ActionType.SET_REMOVE_CANCELED_FILE,
          payload: { filename },
        });
        return;
      }

      if (times <= 0) {
        if (response.data.status === 404) {
          dispatch({
            type: ActionType.SET_REMOVE_CANCELED_FILE,
            payload: { filename },
          });
        }
        return;
      }
      times = times - 1;
      setTimeout(execDelete, 5000);
    };

    execDelete();
  } catch (error) {
    manageError(error);
  }
};

export const fetchProfessions = async (
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  try {
    const response: AxiosResponse = await axios.get(
      `${process.env.NEXT_PUBLIC_LANDING_URL}/api/users/professions`
    );

    dispatch({
      type: ActionType.SET_PROFESSIONS,
      payload: response.data.data,
    });
  } catch (error) {
    manageError(error);
  }
};

export const fetchSpecTypes = async (
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  try {
    const { data } = await axiosRequest.get(`/api/spec/subtypes`);

    dispatch({
      type: ActionType.SET_SPEC_TYPES,
      payload: data,
    });
  } catch (error) {
    manageError(error);
  }
};

export const setSpecId = async (
  dispatch: React.Dispatch<Action>,
  specId: string | number
): Promise<void> => {
  dispatch({
    type: ActionType.SET_SPEC_ID,
    payload: specId,
  });
};

export const setSpecData = async (
  dispatch: React.Dispatch<Action>,
  specData: SpecInfoData
): Promise<void> => {
  dispatch({
    type: ActionType.SET_SPEC_INFO,
    payload: specData,
  });
};

export const createSpec = async (
  dispatch: React.Dispatch<Action>,
  uploadId: string,
  title: string | undefined,
  type: 'spec' | 'intro',
  duration: number | undefined,
  specType: string,
  router: NextRouter
): Promise<void> => {
  try {
    dispatch({ type: ActionType.START_LOADING });

    const { data } = await axiosRequest.post('/api/video', {
      uploadId,
      type,
      specType,
      duration,
      title,
    });

    if (type === 'spec')
      await sendNotification(
        `/api/notifications/specs/${data.id}`,
        'Your video has been uploaded! You can continue with your submission while it finishes processing.'
      );

    dispatch({
      type: ActionType.RESET_LOAD_VIDEO,
    });
    dispatch({ type: ActionType.STOP_LOADING });

    if (router) router.push(`/account/manage-content/${data.id}`);
  } catch (error) {
    manageError(error);
    if (type === 'intro') setError(dispatch, 'Error updating intro video.');
    else setError(dispatch, 'Error creating new submission.');
  }
};

export const updateIntroVideo = async (
  dispatch: React.Dispatch<Action>,
  uploadId: string,
  next: () => void | undefined
): Promise<void> => {
  try {
    dispatch({ type: ActionType.START_LOADING });

    setTimeout(async () => {
      const { data } = await axiosRequest.post('/api/video/intro', {
        uploadId,
      });

      dispatch({
        type: ActionType.SET_INTRO_VIDEO,
        payload: data.sourceUrl,
      });
      if (next) next();
      else dispatch({ type: ActionType.STOP_LOADING });
    }, 2000);
  } catch (error) {
    manageError(error);
    setError(dispatch, 'Error updating intro video.');
  }
};

export const updateSpec = async (
  dispatch: React.Dispatch<Action>,
  specId: string,
  title: string,
  type: 'intro' | 'spec',
  uploadId: string,
  duration?: number | string
): Promise<boolean> => {
  try {
    dispatch({ type: ActionType.START_LOADING });
    let updateData: any = {
      uploadId,
    };
    if (type === 'spec') {
      updateData.specId = parseInt(specId);
      updateData.title = title;
      if (duration) updateData.duration = duration;
    }
    const { data } = await axiosRequest.put('/api/video', updateData);

    dispatch({
      type: ActionType.RESET_LOAD_VIDEO,
    });
    dispatch({
      type: ActionType.SET_SPEC_INFO,
      payload: {
        title: data.title,
        videourl: data.source_url,
        thumbnailurl: '',
      },
    });
    dispatch({ type: ActionType.STOP_LOADING });
    return true;
  } catch (error) {
    manageError(error);
    setError(dispatch, 'Error replacing the submission video.');
    return false;
  }
};

export const deleteSpec = async (
  dispatch: React.Dispatch<Action>,
  specId: string | undefined,
  router: NextRouter | undefined
): Promise<boolean> => {
  dispatch({ type: ActionType.START_LOADING_SCREEN });
  try {
    let updateData: any = {};

    if (specId) updateData.specId = parseInt(specId);
    const { data } = await axiosRequest.delete('/api/video', {
      data: updateData,
    });

    dispatch({
      type: ActionType.SET_SNACKBAR,
      payload: {
        variant: 'error',
        message: 'Your submission has been deleted',
      },
    });

    if (router) router.push('/account/manage-content/');
    return true;
  } catch (error) {
    manageError(error);
    setError(dispatch, 'Error deleting submission.');
    dispatch({ type: ActionType.STOP_LOADING_SCREEN });
    return false;
  }
};

export const deleteIntroVideo = async (
  dispatch: React.Dispatch<Action>
): Promise<boolean> => {
  dispatch({ type: ActionType.START_LOADING_SCREEN });
  dispatch({ type: ActionType.START_LOADING });
  try {
    const { data } = await axiosRequest.delete('/api/video/intro');
    dispatch({
      type: ActionType.SET_SNACKBAR,
      payload: {
        variant: 'error',
        message: 'Your submission has been deleted',
      },
    });

    dispatch({
      type: ActionType.SET_INTRO_VIDEO,
      payload: undefined,
    });
    dispatch({
      type: ActionType.SET_INTRO_THUMBNAIL,
      payload: undefined,
    });

    dispatch({ type: ActionType.STOP_LOADING });
    dispatch({ type: ActionType.STOP_LOADING_SCREEN });
    return true;
  } catch (error) {
    manageError(error);
    dispatch({ type: ActionType.STOP_LOADING });
    dispatch({ type: ActionType.STOP_LOADING_SCREEN });
    setError(dispatch, 'Error deleting submission.');
    return false;
  }
};

export const updateSpecInfo = async (
  dispatch: React.Dispatch<Action>,
  updates: SpecInfoData,
  specId: string | number
): Promise<boolean> => {
  try {
    const { data } = await axiosRequest.patch(`/api/spec/edit/${specId}`, {
      status: updates.status,
      title: updates.title,
      spec_type: updates.specType,
      price: updates.price,
      included_in_sale: updates.includedInSale,
      additional_services: updates.additionalServices,
      brands: updates.brands,
      description: updates.description,
      keywords: updates.keywords,
    });

    dispatch({
      type: ActionType.SET_SPEC_INFO,
      payload: updates,
    });
    return true;
  } catch (error) {
    manageError(error);
    return false;
  }
};

export const startLoading = (dispatch: React.Dispatch<Action>) => {
  dispatch({
    type: ActionType.START_LOADING,
  });
};

export const stopLoading = (dispatch: React.Dispatch<Action>) => {
  dispatch({
    type: ActionType.STOP_LOADING,
  });
};

export const updateSpecInfoParam =
  (key: SpecParams, dispatch: React.Dispatch<Action>) =>
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
        | React.KeyboardEvent<HTMLInputElement>
        | string
        | string[]
        | number
    ): void => {
      dispatch({
        type: ActionType.SET_SPEC_INFO_PARAM,
        payload: {
          key,
          value:
            typeof event !== 'object' || Array.isArray(event)
              ? event
              : event.currentTarget.value,
        },
      });
    };

export const submitSpec = (
  dispatch: React.Dispatch<Action>,
  id: number | string,
  route: NextRouter
): void => {
  route.push('/account/manage-content');
  dispatch({
    type: ActionType.SET_GLITTERING,
    payload: id,
  });
  dispatch({
    type: ActionType.SET_SNACKBAR,
    payload: {
      variant: 'pending',
      message: 'Your content has been submitted and is pending review!',
    },
  });
};

export const fetchUserData = async (
  dispatch: React.Dispatch<Action>,
  id: string | number
): Promise<void> => {
  try {
    dispatch({ type: ActionType.START_LOADING_SCREEN });
    const encryptedId = id;
    const response = await axiosRequest.get(`/api/creators/get/${encryptedId}`);
    const { data } = response;

    if (data) {
      dispatch({
        type: ActionType.SET_CREATORID,
        payload: data.id,
      });

      dispatch({
        type: ActionType.SET_USER_INFO,
        payload: {
          displayName: data.full_name,
          username: data.user_name,
          headline: data.headline,
          location: data.address,
          introduction: data.introduction,
          onlinePortfolio: data.site_url,
          professions: data.professions.map(
            (profession: { id: number; display: string }) => profession.id
          ),
          bio: data?.bio || '',
          customUrl: data.custom_url.toLowerCase(),
          socialNetworks: data.web === null ? {} : data.web,
          thumbnailurl: data.thumbnailurl,
          videourl: data.videourl,
          termsAcceptance: data.terms_acceptance,
        },
      });
    }
    dispatch({ type: ActionType.STOP_LOADING_SCREEN });
  } catch (error) {
    manageError(error);
  }
};

export const fetchSpecData = async (
  dispatch: React.Dispatch<Action>,
  id: string | number
): Promise<boolean> => {
  try {
    dispatch({ type: ActionType.START_LOADING_SCREEN });
    const response = await axiosRequest.get(`/api/spec?id=${id}`);
    const { data } = response;

    if (data) {
      dispatch({
        type: ActionType.START_SPEC_INFO,
        payload: {
          title: data.title,
          specType: data.spec_type,
          description: data.description,
          status: data.status,
          price: data.price,
          includedInSale: data.included_in_sale,
          additionalServices: data.additional_services,
          brands: data.brands,
          videourl: data.videoUrl,
          thumbnailurl: data.thumbnail_url,
          keywords: data.keywords,
          playbackId: data.playback_id,
          notes: data.notes,
        },
      });

      dispatch({
        type: ActionType.SET_SPEC_ID,
        payload: id,
      });

      dispatch({
        type: ActionType.SET_FILES,
        payload: data.releaseforms || [],
      });

      dispatch({ type: ActionType.STOP_LOADING_SCREEN });
      return true;
    } else {
      dispatch({ type: ActionType.STOP_LOADING_SCREEN });
      return false;
    }
  } catch (error) {
    manageError(error);
    dispatch({ type: ActionType.STOP_LOADING_SCREEN });
    return false;
  }
};

export const fetchSpecs = async (
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  try {
    dispatch({ type: ActionType.START_LOADING_SCREEN });
    const response = await axiosRequest.get(`/api/spec`);
    const { data } = response;
    if (data) {
      dispatch({
        type: ActionType.SET_SPECS,
        payload: data
          .filter((spec: SpecInfoData) => spec.status !== 'deleted')
          .map((spec: any) => ({
            ...spec,
            duration: secondsToHms(hmsToSeconds(spec.duration)),
          })),
      });
    }
    dispatch({ type: ActionType.STOP_LOADING_SCREEN });
  } catch (error) {
    manageError(error);
  }
};

export const setUserData = async (
  dispatch: React.Dispatch<Action>,
  userData: UserInfoData
): Promise<void> => {
  dispatch({
    type: ActionType.SET_USER_INFO,
    payload: userData,
  });
};

export const updateUserInfo = async (
  dispatch: React.Dispatch<Action>,
  updates: CreatorModel,
  userId: string
): Promise<boolean> => {
  try {
    const response = await axiosRequest.post(`/api/creators/${userId}`, {
      ...updates,
    });

    dispatch({
      type: ActionType.SET_USER_INFO,
      payload: {
        displayName: response.data.full_name,
        username: response.data.user_name,
        headline: response.data.headline,
        location: response.data.address,
        introduction: response.data.introduction,
        onlinePortfolio: response.data.site_url,
        professions: response.data.professions.map(
          (profession: { id: number; display: string }) => profession.id
        ),
        bio: response.data?.bio || '',
        customUrl: response.data.custom_url.toLowerCase(),
        socialNetworks: response.data.web === null ? {} : response.data.web,
        termsAcceptance: response.data.terms_acceptance,
      },
    });

    return true;
  } catch (error) {
    manageError(error);
    return false;
  }
};

export const updateUserInfoParam =
  (key: ProfileParams, dispatch: React.Dispatch<Action>) =>
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
        | string[]
    ): void => {
      dispatch({
        type: ActionType.SET_USER_INFO_PARAM,
        payload: {
          key,
          value: Array.isArray(event) ? event : event.target.value,
        },
      });
    };

export const updateUserInfoSocialNetworks = (
  value: SocialNetworks,
  dispatch: React.Dispatch<Action>
) => {
  dispatch({
    type: ActionType.SET_USER_INFO_SOCIAL_NETWORK,
    payload: value,
  });
};

export const updateUserLocation = (
  dispatch: React.Dispatch<Action>,
  location: PlaceType
) => {
  dispatch({
    type: ActionType.SET_USER_INFO_PARAM,
    payload: {
      key: 'location',
      value: location,
    },
  });
};

export const fetchKeywords = async (
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  try {
    const response: AxiosResponse = await axiosRequest.get(`/api/keywords`);

    dispatch({
      type: ActionType.SET_AVAILABLE_KEYWORDS,
      payload: response.data,
    });
  } catch (error) {
    manageError(error);
  }
};

export const fetchInvitationData = async (
  dispatch: React.Dispatch<Action>,
  id: string | number
): Promise<void> => {
  try {
    dispatch({ type: ActionType.START_LOADING_SCREEN });
    const encryptedId = id;
    const response = await axiosRequest.get(`/api/creators/${encryptedId}`);
    dispatch({
      type: ActionType.SET_INVITATION_DATA,
      payload: response.data,
    });
    dispatch({ type: ActionType.STOP_LOADING_SCREEN });
  } catch (error) {
    manageError(error);
  }
};

export const fetchUserSettingsData = async (
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  try {
    const response1 = await axiosRequest.get(`/api/settings`);
    const response2 = await axiosRequest.get(`/api/settings/agreement/signed`);

    const { email, date_of_birth, name } = response1.data;
    const { termsAcceptance } = response2.data;

    dispatch({
      type: ActionType.SET_USER_SETTINGS_DATA,
      payload: { email, date_of_birth, name, termsAcceptance },
    });
  } catch (error) {
    manageError(error);
  }
};

export const agreeCreatorAgreement = async (
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  dispatch({ type: ActionType.START_LOADING });
  try {
    await axiosRequest.post('api/settings/agreement/signed');
  } catch (error) {
    manageError(error);
  }
  dispatch({ type: ActionType.STOP_LOADING });
};

export const getAllNotifications = async (
  id: number,
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  try {
    const searchById = id > 0 ? `?id=${id}` : '';
    const response = await axiosRequest.get(`/api/notifications${searchById}`);

    if (response.data) {
      dispatch({
        type: ActionType.SET_ALL_NOTIFICATIONS,
        payload: response.data as NotificationsResponseModel[],
      });
    }
  } catch (error) {
    manageError(error);
  }
};

export const initNotificationsWebHook = (
  id: number,
  handleNotificationsInsert: (
    payload: SupabaseRealtimePayload<NotificationsModel>
  ) => void
) => {
  const notificationsWebHook = supabase
    .from(`notifications:user_id_to=eq.${id.toString()}`)
    .on('INSERT', handleNotificationsInsert)
    .subscribe();
};

export const initSpecsNotificationsWebHook = (
  creatorId: number,
  handleSpecsNotificationsUpdate: (
    payload: SupabaseRealtimePayload<CreatorSpecsModel>
  ) => void
) => {
  const specNotifications = supabase
    .from(`creator_specs:creator_id=eq.${creatorId.toString()}`)
    .on('UPDATE', handleSpecsNotificationsUpdate)
    .subscribe();
};

export const sendNotification = async (url: string, message: string) => {
  try {
    const response = await axiosRequest.post(`${url}`, { message });

    return response.data;
  } catch (error) {
    manageError(error);
  }
};

export const updateNotifications = async (
  body: NotificationsModel[],
  dispatch: React.Dispatch<Action>
): Promise<void> => {
  try {
    const response = await axiosRequest.patch('/api/notifications/edit', body);

    if (response.data) {
      dispatch({
        type: ActionType.SET_NOTIFICATION_AS_READ,
        payload: response.data as NotificationsResponseModel[],
      });
    }
  } catch (error) {
    manageError(error);
  }
};

export const saveThumbnail = async (
  dispatch: React.Dispatch<Action>,
  time: string,
  specId: string | number | undefined,
  image?: string | undefined
): Promise<boolean> => {
  dispatch({ type: ActionType.START_LOADING });

  try {
    let payload: { start?: string; specId?: string | number; image?: string } =
      {};

    if (time && !image) {
      payload.start = time;
    } else if (image) {
      payload.image = image;
    } else return false;

    if (specId) payload.specId = specId;

    const { data } = await axiosRequest.post('api/video/thumbnail', {
      ...payload,
    });

    dispatch({
      type: specId
        ? ActionType.SET_SPEC_THUMBNAIL
        : ActionType.SET_INTRO_THUMBNAIL,
      payload: data.thumbnail_url,
    });

    dispatch({
      type: ActionType.SET_INTRO_MESSAGE,
      payload: {
        message: 'Thumbnail saved',
        status: 'success',
      },
    });

    setTimeout(() => {
      dispatch({
        type: ActionType.CLOSE_INTRO_MESSAGE,
      });
    }, ACTION_DELAY);

    dispatch({ type: ActionType.STOP_LOADING });
    return true;
  } catch (error: any) {
    dispatch({ type: ActionType.STOP_LOADING });
    dispatch({
      type: ActionType.SET_INTRO_MESSAGE,
      payload: {
        message: 'Thumbnail could not be saved',
        status: 'error',
      },
    });

    setTimeout(() => {
      dispatch({
        type: ActionType.CLOSE_INTRO_MESSAGE,
      });
    }, ACTION_DELAY);
    manageError(error);
    return false;
  }
};
