import { useContext, useEffect } from 'react';
import { store } from '../../state/context';
import Image from 'next/image';
import Link from 'next/link';
import Alerts from '../Alerts';
import Breadcrumb from '../Breadcrumb';
import Profile from '../Profile';
import classes from './Header.module.scss';
import { useWindowSize } from '../../utils';
import logoSm from '../../public/images/x4y-icon.svg';
import logo from '../../public/images/logo.svg';
import Snackbar from '../Snackbar';
import Button from '../Button';
import { SIGNUP_URL, WINDOW_XS } from '../../utils/constants';
import { fetchUserData } from '../../state/action-creators';
import { useNotifications } from '../../state/hooks/useNotifications';

interface HeaderProps {
  token?: string;
  id?: number;
}

const Header: React.FC<HeaderProps> = ({ token, id }): JSX.Element => {
  const { state, dispatch } = useContext(store);
  const { editProfile, notifications, specState } = state;
  const { creatorId } = specState;
  const { userData } = editProfile;
  const { displayName } = userData;
  const [width]: number[] = useWindowSize();
  const {
    initNotifications,
    initSpecNotifications,
    getNotifications,
    removeUserSubscriptions,
  } = useNotifications();

  const openLoginPage = () => {
    window.open(
      `${process.env.NEXT_PUBLIC_LANDING_URL}/login?from=app.x4y.com`,
      '_blank'
    );
  };

  useEffect(() => {
    if (!displayName && id) {
      fetchUserData(dispatch, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (creatorId && id) {
      getNotifications();
      removeUserSubscriptions(id, creatorId);
      initNotifications(id);
      initSpecNotifications(parseInt(creatorId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId, id]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.breadcrumbs}>
          <Link href={`${process.env.NEXT_PUBLIC_LANDING_URL}`}>
            <a className={classes.logo} title='X4Y Home Page'>
              {width <= WINDOW_XS ? (
                <Image
                  src={logoSm}
                  alt='X4Y logo'
                  width='24'
                  height='24'
                />
              ) : (
                <Image
                  src={logo}
                  alt='X4Y logo'
                  width={82.82}
                  height='24'
                />
              )}
            </a>
          </Link>
          <Breadcrumb
            name={
              editProfile.userData.customUrl
                ? editProfile.userData.customUrl.split(' ')[0]
                : ''
            }
            customUrl={editProfile.userData.customUrl || ''}
          />
        </div>

        <div className={classes.profile}>
          {token ? (
            <>
              <Button
                size='xxs'
                variant='default'
                onClick={() =>
                  window
                    ?.open(
                      `${process.env.NEXT_PUBLIC_LANDING_URL}/${editProfile.userData.customUrl}`,
                      '_blank'
                    )
                    ?.focus()
                }>
                View Profile
              </Button>
              <div className={classes.alert}>
                <Alerts data={notifications} />
              </div>
              <div>
                <Profile letter={(displayName && displayName[0]) || ''} />
              </div>
            </>
          ) : (
            <>
              <div>
                <Button onClick={openLoginPage}>Sign In</Button>
              </div>
              <div>
                <Button
                  variant='blue'
                  onClick={() => window.open(SIGNUP_URL, '_blank')?.focus()}>
                  Creator Signup
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      {state.specState.snackbar.message ? (
        <Snackbar variant={state.specState.snackbar.variant}>
          {state.specState.snackbar.message || ''}
        </Snackbar>
      ) : null}
    </>
  );
};

export default Header;
