import classes from './Breadcrumb.module.scss';

interface BreadcrumbProps {
  name: string;
  customUrl: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ name }): JSX.Element => {
  return name ? (
    <div className={classes.container}>
      <span>/</span>
      <span>Account</span>
    </div>
  ) : (
    <></>
  );
};

export default Breadcrumb;
