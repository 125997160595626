import Image from 'next/image';
import classes from './Alerts.module.scss';
import iconOff from '../../public/images/alert-icon-off.svg';
import iconOn from '../../public/images/alert-icon-on.svg';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import Button from '../Button';
import Profile from '../Profile';
import clsx from 'clsx';
import { isChildren } from '../../utils';
import { NotificationsResponseModel } from '../../state/types/databaseSchemas';
import { useNotifications } from '../../state/hooks/useNotifications';

interface AlertProps {
  data: NotificationsResponseModel[];
}

interface Alert {
  active: boolean;
  message: string | JSX.Element;
  date: string;
  logo: string;
}

const x4yProfile = '/images/x4y-profile.svg';

const Alert: React.FC<NotificationsResponseModel> = ({
  id,
  message,
  status,
  user_id_from,
  user_id_to,
  user_from_name,
  user_to_name,
  created_at,
}) => {
  const logo = user_id_from === 0 ? x4yProfile : 'C';
  const active = status === 1 ? true : false;

  return (
    <li>
      <div>
        {logo.indexOf('.') > 0 ? (
          <Image src={logo} alt='alert icon' height='40px' width='40px' />
        ) : (
          <Profile letter={logo} />
        )}
      </div>
      <div className={classes.alertMessage}>
        <p>{message}</p>
        <sub>{created_at}</sub>
      </div>
      <div className={classes.dotWrapper}>
        {active && <span className={classes.dot} />}
      </div>
    </li>
  );
};

const Alerts: React.FC<AlertProps> = ({ data }): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<
    NotificationsResponseModel[]
  >([]);
  const popperCard: any = useRef();
  const { markAsReadNotifications } = useNotifications();

  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
  });

  const openPopper = () => {
    setOpen(!open);
    popperCard.current.focus();
  };

  const setAllReads = () => {
    let update = notifications.map(({ id, status }) => ({ id, status: 2 }));
    markAsReadNotifications(update);
  };

  useEffect(() => {
    const onClick = (e: any) => {
      if (!isChildren(e, 'popper-card', 0, 5)) setOpen(false);
    };

    if (open) {
      window.addEventListener('click', onClick);
      return () => {
        window.removeEventListener('click', onClick);
      };
    }
  }, [open, data]);

  const deleteRepeatedNotifications = (
    notifications: NotificationsResponseModel[]
  ) => {
    let cleansed: NotificationsResponseModel[] = [];

    cleansed = [
      ...new Map(notifications.map((item) => [item.id, item])).values(),
    ];
    return cleansed;
  };

  useEffect(() => {
    if (data !== notifications)
      setNotifications(deleteRepeatedNotifications(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div
        ref={setPopperElement}
        style={styles.popper}
        className={clsx(open ? classes.root : classes.popperHidden)}
        id='popper-card'
        {...attributes.popper}>
        <main className={classes.card} ref={popperCard}>
          <header>
            <h4>Notifications</h4>
            <Button
              variant='gray'
              size='xxs'
              onClick={setAllReads}
              disabled={!data.some((message) => message.status === 1)}>
              Mark All Read
            </Button>
          </header>
          <ul>
            {notifications.map((alert, index) => (
              <Alert {...alert} key={`alert-${index}`} />
            ))}
          </ul>
        </main>
      </div>
      <div
        className={classes.container}
        onClick={openPopper}
        ref={setReferenceElement}>
        {open ? (
          <Image
            src='/images/cancel-icon-black.svg'
            width='24px'
            height='24px'
            alt='close alert icon'
          />
        ) : (
          <Image
            src={
              data.filter((element) => element.status === 1).length > 0
                ? iconOn
                : iconOff
            }
            width='24px'
            height='24px'
            alt='alert icon'
          />
        )}
      </div>
    </>
  );
};

export default Alerts;
