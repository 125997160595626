import { useContext, useEffect } from 'react';
import classes from './Snackbar.module.scss';
import { store } from '../../state/context';
import { setError } from '../../state/action-creators';
import { SNACKBAR_TIMEOUT } from '../../utils/constants';
import clsx from 'clsx';

interface Props {
  children: string;
  variant?: 'pending' | 'error' | 'success';
}

const Snackbar: React.FC<Props> = ({
  children,
  variant = 'pending',
}): JSX.Element => {
  const { state, dispatch } = useContext(store);

  useEffect(() => {
    const interval = setInterval(() => {
      setError(dispatch, null);
    }, SNACKBAR_TIMEOUT);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={clsx(classes.container, classes[variant])}> {children}</div>
  );
};

export default Snackbar;
