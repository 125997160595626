import clsx from 'clsx';
import classes from './Button.module.scss';

interface ButtonProps {
  children: string;
  type?: 'button' | 'submit';
  variant?:
  | 'default'
  | 'blue'
  | 'marine'
  | 'gray'
  | 'black'
  | 'outlined'
  | 'outlined-black'
  | 'thumbnail'
  | 'error'
  | 'loading';
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const Button: React.FC<ButtonProps> = ({
  children,
  type = 'button',
  variant = 'default',
  disabled = false,
  onClick,
  fullWidth = false,
  size = 'sm',
}): JSX.Element => {
  return (
    <>
      <input
        type={type}
        className={clsx(
          classes[size],
          classes[variant],
          fullWidth && classes.fullWidth
        )}
        onClick={onClick}
        value={children}
        disabled={disabled}
      />
    </>
  );
};

export default Button;
