import Link from 'next/link';
import classes from './Profile.module.scss';

interface ProfileProps {
  letter: string;
}

const Profile: React.FC<ProfileProps> = ({ letter }): JSX.Element => {
  return (
    <Link href='/account/dashboard'>
      <a className={classes.container}>
        <div>{letter.toUpperCase()}</div>
      </a>
    </Link>
  );
};

export default Profile;
