import {
  getAllNotifications,
  initNotificationsWebHook,
  initSpecsNotificationsWebHook,
  sendNotification,
  updateNotifications,
} from '../action-creators';
import { useStore } from './useStore';
import { ActionType } from '../action-types';
import { SupabaseRealtimePayload } from '@supabase/supabase-js';
import {
  KeywordsResponseModel,
  NotificationsModel,
  SpecsModel,
  CreatorSpecsModel,
} from '../types/databaseSchemas';
import { specStatus } from '../../utils/constants';
import { supabase } from '../../utils/supabase';
import { update } from 'lodash';

export const useNotifications = () => {
  const { state, dispatch } = useStore();

  const getNotifications = (id?: number) => {
    getAllNotifications(id || -1, dispatch);
  };

  const handleNotificationsInsert = async (
    payload: SupabaseRealtimePayload<NotificationsModel>
  ) => {
    await getAllNotifications(payload.new.id!, dispatch);
  };

  const handleSpecsNotificationsUpdate = async (
    payload: SupabaseRealtimePayload<CreatorSpecsModel>
  ) => {
    const updated = payload.new;
    const old = payload.old;
    if (updated.status !== old.status) {
      let message: string | undefined = handleMessage(
        updated.title || '',
        updated.status || ''
      );

      if (updated.status === specStatus.DRAFT) {
        const keywords = await supabase.rpc<KeywordsResponseModel>(
          'get_spec_keywords',
          {
            p_spec_id: updated.spec_id,
          }
        );

        dispatch({
          type: ActionType.SET_KEYWORDS,
          payload: keywords.data,
        });

        dispatch({ type: ActionType.STOP_LOADING_SCREEN });
      }
      await sendNotification(
        `/api/notifications/specs/${updated.spec_id}`,
        message || ''
      );
    }
  };

  const initNotifications = (id: number) => {
    initNotificationsWebHook(id,handleNotificationsInsert);
  };

  const initSpecNotifications = (creatorId: number) => {
    initSpecsNotificationsWebHook(creatorId,handleSpecsNotificationsUpdate);
  };

  const markAsReadNotifications = (body: NotificationsModel[]) => {
    updateNotifications(body, dispatch);
  };

  const removeUserSubscriptions = (id: any, creatorId: any) => {
    const subscriptions = supabase.getSubscriptions()
    subscriptions.map(subscription => { 
        if(subscription.topic.split(':')[2] === "notifications"){
          removeSubscription(subscription, id)
        }
        if(subscription.topic.split(':')[2] === "creator_specs"){
          removeSubscription(subscription, creatorId)
        }
      }
    );
    
  };

  const removeSubscription = (subscription: any, id: any) => {
    if(subscription.topic.split('eq.')[1] === id.toString())
      supabase.removeSubscription(subscription)
  };

  const handleMessage = (title: string, status: string) => {
    switch (status) {
      case specStatus.PROCESSING:
        return 'Your video has been uploaded! You can continue with your submission while it finishes processing.';
      case specStatus.DRAFT:
        return `Your submission has been saved as a Draft. Don't forget to submit when you're ready.`;
      case specStatus.PENDING_REVIEW:
        return `Your "${title}" content is now in review.`;
      case specStatus.APPROVED:
        return `Congrats, {creator_name}! Your submission has been approved and is now available for purchase.`;
      case specStatus.CHANGES_REQUIRED:
        return `{creator_name}, your "${title}" submission requires changes: {note}`;
      case specStatus.REJECTED:
        return `Your submission has been Rejected.`;
      case specStatus.SOLD:
        return `Congrats, {creator_name}! Your submission has been sold and is no longer on the market.`;
    }
  };

  return {
    state,
    dispatch,
    removeUserSubscriptions,
    getNotifications,
    initNotifications,
    initSpecNotifications,
    markAsReadNotifications,
  };
};
